
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Font awesome
@import "~font-awesome/scss/font-awesome";

@import "~medium-editor/src/sass/medium-editor";

@import "~medium-editor/src/sass/themes/default";
// Bulma
@import "~bulma/sass/utilities/functions";
@import "~bulma/sass/utilities/mixins";
@import '~jquery-ui/themes/base/all.css';
@import "~dropzone/dist/min/basic.min.css";
@import "~dropzone/dist/min/dropzone.min.css";
$size-normal: 14px;
$mauve: #5B3C4B;
$mauve-light: #9F9FBA;
$info: $mauve;
$info-invert: findColorInvert($mauve);
$body-background: #FFF;
$fullhd: 1192px;
@import "node_modules/bulma/bulma";
@import "node_modules/sweetalert/dist/sweetalert";

nav.nav-header {
  height: 80px;
  margin-top:20px;
}

nav.nav-main {
  height: 2.75rem;
  border-top: 1px solid #bbbbbb;
  border-bottom: 1px solid #999999;
  margin-top: 20px;

  a.nav-item:not(.button):hover:hover {
    background-color: $mauve;
    color: $white;
  }

  .nav-toggle {
    height: 2.6rem;
    &.is-active span {
      background-color: $info;
    }
  }
}
figure.has-shadow {
  box-shadow: 0px 5px 2px rgba(0,0,0,0.1);
  transition: all 0.5s;
  &:hover {
    box-shadow: none;
  }
}

.content-container.books {
  padding: 50px 0 20px;
}


.books .title a {
  color: $text;
  &:hover {
    text-decoration: none;
    border-bottom: none;
  }
}

.books .no-image {
  background: #ccc;
  color: #eee;
  text-align: center;
  padding:55px 20px;
  i {
    font-size:8vw;
  }
}

.books .book {
  position: relative;
  //padding-bottom: 40px;
  .details {
    padding-top:10px;
    text-align: center;
    padding-bottom: 20px;
  }

  .book-title, .book-author, .book-publisher, .book-price {
    font-size: 0.75rem;
    a {
      color: $grey-dark;
    }
  }

  .book-publisher {
    color: $grey-light;
  }

  .book-title {
    font-weight:bold;
  }

  .add-to-cart-container {
    position: absolute;
    bottom: 0;
    display: block;
    opacity: 0;
    left:0;
    right:0;
    transition: all 0.5s;
  }
  &:hover .add-to-cart-container {
    opacity:1;
  }
}

.pagination {
  li {
    display: inline-block;
  }
}
@media screen and (min-width: $tablet) {
  .nav-menu.nav-left {
    overflow: hidden;
  }
}

@media screen and (max-width: $tablet - 1px) {
  .nav-menu.nav-left {
    background-color: white;
    box-shadow: 0 4px 7px rgba(10, 10, 10, 0.1);
    left: 0;
    display: none;
    right: 0;
    top: 100%;
    position: absolute;
    &.is-active {
      display: block;
    }
  }
}

.nav-item.is-brand  img {
  max-height: 120px;
}

p.control.nav-item {
  margin-bottom: 0;
}

.content-container {
  padding: 50px 0;
}

.menu {
  .columns {
    margin-top:0;
  }
  text-align: center;
  a {
    display: block;
    padding: 25px 0;
    background-color: #ddd;
  }
}

.intro {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  .columns {
    padding-top: 30px;
  }
  .title {
    margin: 0;
    font-size: 50px;
    padding: 50px;
    &:not(:last-child) {
      border-right: 1px solid $text-color;
    }
  }
}

.book-view {
  .subtitle {
    font-weight: normal;
  }

  .book-right-col {
    text-align: right;
  }
}

.store-map {
  margin: 20px 0;
}

.contact-form {
  padding-top: 20px;
}

.title-level {
  .field-body {
    flex-grow: 0;
  }
}

.cart {
  font-size: 14px;
  .field {
    label {
      font-weight: normal;
      abbr {
        color: red;
        font-weight: 700;
        border: 0;
      }
    }
  }
  .remove-from-cart-button {
    vertical-align: middle;
  }

  .is-disabled {
    .title, label, abbr, strong {
      color: #bbb !important;
    }
  }
}

.help.button {
  height: auto;
  white-space: normal;
  min-height: 2.25em;
}

@media screen and (min-width: 1000px) {
  .column.is-one-fifth-desktop {
    -webkit-box-flex: 0;
    flex: none;
    width: 20%;
  }
}

@media screen and (max-width: 768px) {
  .nav-header {
    display: block;
    height: auto !important;
    .nav-left {
      display: block;
    }
  }
  .content-container {
    padding:20px;
  }
  .columns.books {
    padding: 0 20px;
  }
  .books .book .add-to-cart-container {
    opacity: 1 !important;
  }
  .books .book {
    margin-bottom:30px;
    word-wrap: break-word;
  }

  .title-level {
    h1.title.is-1 {
      font-size: 2rem;
    }
  }
}

@media screen and (max-width: 300px) {
  .nav-header .nav-right {
    input {
      width: 120px !important;
    }
  }

}

.button.is-success-light {
  background-color: $mauve-light;
  border-color: transparent;
  color: #fff;
}

.share-dropdown {
  padding-top: 10px;
  a.dropdown-item {
    padding-right: 1em;
  }
}

.ui-datepicker {
  z-index: 50 !important;
  background: #fff;
  box-shadow: 0px 2px 20px rgba(0,0,0,0.4);
}

.medium-editor-element {
  outline: 0;
  border-bottom: 1px solid #aaa;
}

.select2-wrapper {
  .select2-container {
    .select2-selection {
      transition: border-color $speed;
      font-family: $family-sans-serif;
      height: 2.285em;
      line-height: 1.5;
      font-size: 1rem;
      outline: none !important;
      display: inline-flex;
      align-items: center;
      width: 100%;
      border-color: $border;
      border-radius: $radius;
      &:hover {
        border-color: $border-hover;
      }
      .select2-selection__rendered {
        padding-left: 0.75em;
        padding-right: 0.75em;
      }
      .select2-selection__arrow {
        height: 100%;
        top: 0px;
      }
    }

    &.select2-container--open {
      .select2-selection {
        border-color: $primary;
        &:hover {
          border-color: $primary;
        }
      }
    }
  }

  &.is-medium {
    .select2-container {
      .select2-selection {
        font-size: $size-6;
      }
    }
  }
  &.is-large {
    .select2-container {
      .select2-selection {
        font-size: $size-5;
      }
    }
  }
}

.select2-container {
  min-width: 300px;
  .select2-dropdown {
    border-color: $primary;
    .select2-search {
      margin: 10px;
      .select2-search__field {
        @extend .input;
        border-radius: $radius !important;
      }
    }

    .select2-results__options {
      max-height: 210px;
      .select2-results__option {
        padding: 0.75em;
        font-family: $family-sans-serif;
        font-size: 1rem;

        &.select2-results__option--highlighted {
          background: $primary;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .column.today {
    padding: 0.75rem 0.5rem;
  }
}

.today {
  border-top: 2px solid #888;
  border-bottom: 2px solid #888;
  padding: 0.75rem 0;
  margin-top: 1em;
  margin-bottom: 2em;
  .today-title, .today-subtitle {
    font-size: 24px;
    font-weight: bold;
    color: #C6C6C6;
    line-height: 28px;
    &.mauve {
      color: $mauve;
    }
  }
  .today-subtitle {
    font-size: 20px;
    line-height: 20px;
  }
  .today-text {
    background-color: #eee;
    border-radius: 10px;
    padding:5px 15px;
    text-align: justify;
    font-size:14px;
    height: 5em;
    overflow: hidden;
    .text-container {
      height:6em;
      overflow: hidden;
    }
  }
  .today-link {
    font-size: 80px;
    @media screen and (max-width: 768px) {
      font-size: 60px;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    a {
      margin-top: -20px;
      font-family: "Times New Roman",Serif;
      text-decoration: none;
      line-height: 20px;
      color: darken($text-color, 20%);
    }
  }
}

.has-text-justified {
  text-align: justify;
}

.dropzone.dz-clickable {
  width: 100%;
}

.inline-flex {
    display:inline-flex !important;
}
.pad-2-5 {
    padding: 0.2rem 0.5rem !important;
}
.pad-top-bot {
    margin: 20px 0px 7px 0px;
}
